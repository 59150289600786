@import url('tailwindcss/base');
@import url('tailwindcss/components');
@import url('tailwindcss/utilities');
@import url('flatpickr/dist/flatpickr');
@import url('flatpickr/dist/themes/light');
@import url('lightgallery/css/lightgallery');
@import url('lightgallery/css/lg-video');
@import url('tiny-slider/dist/tiny-slider');
@import url('tom-select/dist/css/tom-select');
@import url('@uppy/core/dist/style');
@import url('nouislider/dist/nouislider');
@import url('vendor/flag-icon-css/css/flag-icon');
@import url('fonts');

pre > code {
  font-size: 0.9rem !important;
}

@keyframes notification-countdown {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input.quantity-input::-webkit-outer-spin-button,
input.quantity-input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].quantity-input {
  appearance: textfield;
}

/* Fix flickering issue on safari for tiny slider */
.tns-item {
  transform: translate3d(0, 0, 0);
}
